import React from "react"

export default function Tag({
  text
}) {

  let color = "#594c02";
  switch (text) {
    case "Ethics":
      color = "#0e6382";
      break;
    case "Philosophy":
      color = "#910606";
      break;
    case "Animals":
      color = "#06613c";
      break;
    default:
      break;
  }

  const height = 24;
  const fontSize = 12;
  const borderRadius = 6;
  const textColor = "#fdf9f2"

  return (
    <div>
      <div style={{
        float: "left",
        width: "0",
        height: "0",
        borderColor: `transparent ${color} transparent transparent`,
        borderStyle: "solid",
        borderWidth: "12px 12px 12px 0",
      }} />
      <div style={{
        float: "left",
        marginRight: "20px",
        height: `${height}px`,
        lineHeight: `${height}px`,
        fontSize: `${fontSize}px`,
        padding: "0 10px 0 8px",
        background: `${color}`,
        color: `${textColor}`,
        textDecoration: "none",
        MozBorderRadiusBottomRight: `${borderRadius}px`,
        WebkitBorderBottomRightRadius: `${borderRadius}px`,
        borderBottomRightRadius: `${borderRadius}px`,
        MozBorderRadiusTopRight: `${borderRadius}px`,
        WebkitBorderTopRightRadius: `${borderRadius}px`,
        borderTopRightRadius: `${borderRadius}px`,      
      }}>
        {text}
      </div>
    </div>
  )
}