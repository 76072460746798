import React from "react"
import { Link } from "gatsby"

import Tag from "../Tag/Tag"
import "./BlogPostCard.css"

export default function BlogPostCard({
  title,
  date,
  subtitle,
  tags,
  slug,
}) {
  return (
    <div className="blogPostCard">
        <Link to={slug}>
            <span className="date">{date}</span>
            <h2>{title}</h2>
            <p>{subtitle}</p>
            <div className="tagsContainer">
            {tags.map((element, i) => {
            return <Tag text={element} key={i} />
            })}
            </div>
        </Link>
    </div>
  )
}