import React from "react"
import { graphql } from "gatsby"
import BlogPostCard from "../components/BlogPostCard/BlogPostCard"
import { Fragment } from "react"

export default function IndexPage({
  data,
}) {
  return (
    <Fragment>
      <head><title>Ryan Schalk</title></head>
      <div className="centeredReadableContainer">
        <h1>Ryan Schalk</h1>
        <p>Relentless knowledge-seeker, beginning to develop a blog about how to make the best of living in this world.</p>
        <hr />
        {data.allMarkdownRemark.edges.map(({ node }) => {
          const blogPostCardProps = {
            title: node.frontmatter.title,
            date: node.frontmatter.date,
            subtitle: node.frontmatter.subtitle,
            tags: node.frontmatter.tags,
            slug: node.frontmatter.slug,
          };

          return <BlogPostCard {...blogPostCardProps} />
        })}
      </div>
    </Fragment>
  )
}

export const pageQuery = graphql`
{
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
  ) {
    edges {
      node {
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          subtitle
          tags
        }
      }
    }
  }
}
`